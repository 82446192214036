import { SgConnectEnvironment } from 'services/sgConnect/sgConnectEnvironment';

export const DEFAULT_SG_NAME = 'Societe Generale'; // never translated
export const HEADER_SCROLL_DEBOUNCE_IN_MS = 100;

export const NUMBER_OF_ESG = 4;

export enum Environment {
    Test = 'Test',
    Local = 'Local',
    Development = 'Development',
    Homologation = 'Homologation',
    Preview = 'Preview',
    Production = 'Production',
}

const allEnvs: Record<Environment, string[]> = {
    [Environment.Test]: [],
    [Environment.Local]: [
        'local.fr.world.socgen',
        'localhost',
    ],
    [Environment.Development]: [
        'info-new-dev.azureedge.net',
        'info-dev.sgmarkets.com',
    ],
    [Environment.Homologation]: [
        'info-new-uat.azureedge.net',
        'info-uat.sgmarkets.com',
    ],
    [Environment.Preview]: [
        'info-new-preview.azureedge.net',
        'info-preview.sgmarkets.com',
    ],
    [Environment.Production]: [
        'info-new-prd.azureedge.net',
        'info.sgmarkets.com',
    ],
};

export const getEnvironment = (): Environment => {
    if ((window as any)?.__vitest_environment__) {
        return Environment.Test;
    }

    const env = Object.values(Environment)
        .find(x => allEnvs[x].includes(window.location.hostname));

    if (env) {
        return env;
    }

    throw new Error(`Can't find environment from hostname ${window.location.hostname}`);
};

interface IConfiguration {
    baseApiUrls: {
        sgmInfo: string;
        sgConnect: string;
    };
    authorizationEndpoint: string;
    clientId: string;
    scope: string;
    sgConnectEnvironment: SgConnectEnvironment;
    cdnSharedBaseUrl: string;
    sgmarketsWebsiteUrl: string;
    piwik: PiwikConfiguration;
    publicInsightUrl: string;
    analyticsUrl: string;
    spUrl: string;
    developerUrl: string;
    sgMonitoringRumUrl: string;
}

export interface PiwikConfiguration {
    url: string;
    siteId: string;
}

const defaultConfiguration = {
    baseApiUrls: {
        sgmInfo: 'https://sgmarkets-api-info-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
        _sgmInfo: 'https://localhost:7288',
        sgConnect: 'https://sgconnect-hom.fr.world.socgen',
    },
    clientId: '5ed3064f-3f9b-4 61-8727-7b015ab6eb12',
    authorizationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',

    scope: [
        'openid',
        'profile',
    ].join(' '),
    sgConnectEnvironment: SgConnectEnvironment.Homologation,
    cdnSharedBaseUrl: 'https://shared-uat.sgmarkets.com',
    sgmarketsWebsiteUrl: 'https://www-dev.sgmarkets.com',
    piwik: {
        url: 'https://sg-analytics-uat.fr.world.socgen/piwik',
        siteId: '877539844',
    },
    publicInsightUrl: 'https://insight-public-dev.sgmarkets.com',
    analyticsUrl: 'https://analytics.sgmarkets.com',
    spUrl: 'https://sp.sgmarkets.com/public',
    developerUrl: 'https://developer.sgmarkets.com',
    sgMonitoringRumUrl: 'https://insight-realm-apm-hom.fleet.uat.sgmonitoring.dev.euw.gbis.sg-azure.com',
};

const getConfigurationPerEnvironment = (): IConfiguration => {
    const env = getEnvironment();

    switch (env) {
        case Environment.Test:
            return {
                ...defaultConfiguration,
                baseApiUrls: {
                    sgmInfo: 'https://localhost',
                    sgConnect: 'https://localhost',
                },
            };

        case Environment.Local:
            return {
                ...defaultConfiguration,
            };
        case Environment.Development:
            return {
                ...defaultConfiguration,
                baseApiUrls: {
                    sgmInfo: 'https://sgmarkets-api-info-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
                    sgConnect: 'https://sgconnect-hom.fr.world.socgen',
                },
            };

        case Environment.Homologation:
            return {
                ...defaultConfiguration,
                baseApiUrls: {
                    sgmInfo: 'https://api-z-dev.sgmarkets.com/services/info/anonymous/v1',
                    sgConnect: 'https://sgconnect-hom.fr.world.socgen',
                },
                sgmarketsWebsiteUrl: 'https://www-uat.sgmarkets.com',
                publicInsightUrl: 'https://insight-public-uat.sgmarkets.com',
            };

        case Environment.Preview:
        case Environment.Production:
            return {
                ...defaultConfiguration,
                baseApiUrls: {
                    sgmInfo: 'https://api-z.sgmarkets.com/services/info/anonymous/v1',
                    sgConnect: 'https://sso.sgmarkets.com',
                },
                clientId: 'b31d878d-0217-42ee-ae55-98e8ebfb2758',
                authorizationEndpoint: 'https://sso.sgmarkets.com/sgconnect/oauth2/authorize',
                sgConnectEnvironment: SgConnectEnvironment.Production,
                cdnSharedBaseUrl: 'https://shared.sgmarkets.com',
                sgmarketsWebsiteUrl: 'https://sgmarkets.com',
                piwik: {
                    url: 'https://t-log.sgmarkets.com',
                    siteId: '129',
                },
                publicInsightUrl: 'https://insight-public.sgmarkets.com',
                sgMonitoringRumUrl: 'https://t-monitoring-rum.sgmarkets.com/rum/540a239031b64d7fbe34fe54fef041db',
            };
    }
};

export const configuration = getConfigurationPerEnvironment();